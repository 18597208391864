<template>
  <div class="eyeDiseasePage">
    <div>
      <ul>
        <li @click="go('Iframe?type=21', qx.uf_ra_c)">
          <img src="@/assets/img/frameWith/3DScan_2.png" alt="">
          <p>白内障</p>
        </li>
        <li>
          <img src="@/assets/img/frameWith/3DScan_3.png" alt="">
          <p>青光眼</p>
        </li>
        <li>
          <img src="@/assets/img/frameWith/3DScan_5.png" alt="">
          <p>角膜病</p>
        </li>
      </ul>

      <ul style="padding-top: 50px;">
        <li @click="">
          <img src="@/assets/img/frameWith/3DScan_4.png" alt="">
          <p>眼底病</p>
        </li>
        <li @click="">
          <img src="@/assets/img/eye_check.png" alt="">
          <p>眼外伤</p>
        </li>
        <li @click="go('/eyeDiseaseHome/dryEyeTreatment?type=1', qx.uf_examination_mg)">
          <img src="@/assets/img/conrea/contact_inquiry.png" alt="">
          <p>干眼诊疗</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "eyeDiseasePage",
  data() {
    return {
      qx: ''
    }
  },
  created() {
    this.qx = this.$store.state.bases.qx
    // console.log(this.qx)
  },
  methods: {
    go(url, qx) {
      // console.log(qx)
      if (!qx) return this.$alert('请设置相关权限', '提示')
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped lang="scss">
.eyeDiseasePage {
  width: 100vw;
  height: 76vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
h3 {padding: .5rem 0;}
ul {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    padding: 0 1rem;
    cursor: pointer;
    img {
      display: block;
      width: 150px;
      margin-bottom: .2rem;
    }
  }
}
.pd-4 {padding-top: 4vh}
</style>
